import styled from "styled-components";
import { theme } from "../../styles/theme";

export const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% - 2rem);
  padding: 1rem;
  border-radius: 5px;

  input {
    padding: 0.5rem 1rem;
    border: 1px solid ${theme.colors.border};
    border-radius: 5px;
  }

  button {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.textInverted};
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
`;
