import axiosConfig from "../axiosConfig";

const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    const response = await axiosConfig.post(`/auth/login`, {
      email,
      password,
      rememberMe: true,
    });

    return response.data;
  } catch (error: any) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    throw new Error(error.response?.data?.message || error.message);
  }
};

export default { loginUser };
