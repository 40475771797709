import React, { useState } from "react";
import { FaIcons, FaPaperPlane, FaPlane } from "react-icons/fa";
import styled from "styled-components";
import { sendMessage } from "../../services/chats/chatServices";

interface ChatFooterProps {
  to: string;
  getMessages: () => void;
}

const ChatFooter = ({ to, getMessages }: ChatFooterProps) => {
  const [message, setMessage] = useState("");

  const send = async () => {
    await sendMessage({
      to,
      message,
      messageType: "text",
    });

    setMessage("");
    getMessages();
  };

  return (
    <FooterContainer>
      <FaIcons />
      <input
        type="text"
        placeholder="Type a message"
        name="message"
        id="message"
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <FaPaperPlane onClick={send} />
    </FooterContainer>
  );
};

export default ChatFooter;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  position: fixed;
  bottom: 0;
  width: calc(100% - 20px);

  input {
    flex: 1;
    margin: 0 10px;
    padding: 10px;
    border-radius: 20px;
    border: none;
  }
`;
