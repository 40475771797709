interface Theme {
  colors: {
    primary: string;
    secondary: string;
    background: string;
    text: string;
    textSecondary: string;
    textInverted: string;
    border: string;
    borderSecondary: string;
    borderInverted: string;
    error: string;
    success: string;
    rosadoClaro: string;
  };
  fonts: {
    primary: string;
    secondary: string;
  };
}

export const theme: Theme = {
  colors: {
    primary: "#D1345B",
    secondary: "#1f2a48",
    background: "#f5f5f5",
    rosadoClaro: "#d1345b42",
    text: "#333",
    textSecondary: "#666",
    textInverted: "#fff",
    border: "#ddd",
    borderSecondary: "#aaa",
    borderInverted: "#333",
    error: "#ff6347",
    success: "#2ecc71",
  },
  fonts: {
    primary: "Arial, sans-serif",
    secondary: "Georgia, serif",
  },
};
