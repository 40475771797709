import React, { useEffect, useState } from "react";
import { fetchMessages } from "../../services/chats/chatServices";
import { useNavigate, useParams } from "react-router-dom";
import ChatFooter from "./ChatFooter";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ChatMessages from "./components/ChatMessages";
import { Message } from "../../types/types";
import Loading from "../../components/Loading/Loading";

const ChatDetail = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);
  const { id } = useParams();

  const getMessages = async () => {
    if (!id) {
      alert("Contact not found");
      return;
    }
    const response = await fetchMessages(id, 1, 200);

    setMessages(response.messages);
    console.log(response.messages);
  };

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ccc",
        }}
      >
        <FaArrowLeft
          onClick={() => navigate("/")}
          style={{
            cursor: "pointer",
            fontSize: "20px",
          }}
        />
      </div>
      {messages.length > 0 ? (
        <div
          style={{
            textAlign: "center",
            padding: "5px 0px",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#f0f0f0",
          }}
        >
          <p>
            {messages[0].possibleName ? messages[0].possibleName : "Unknown"}
            <span style={{ color: "#a0a0a0" }}>({messages[0].from})</span>
          </p>
          <p></p>
        </div>
      ) : (
        <Loading />
      )}
      <ChatMessages messages={messages} />
      <ChatFooter to={id as string} getMessages={getMessages} />
    </div>
  );
};
export default ChatDetail;
