import React from "react";
import { ChatBubble, ChatBubbleContainer } from "./ChatMessagesStyles";
import { FaFile } from "react-icons/fa";
import { format } from "date-fns";

interface ChatBubbleProps {
  messages: any;
}

const ChatMessages: React.FC<ChatBubbleProps> = ({ messages }) => {
  const findHTMLInText = (text: string) => {
    // Expresión regular para detectar URLs
    const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi;

    // Reemplazar cada URL encontrada con una etiqueta <a>
    const result = text
      .replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" style="color:#D1345B">${url}</a>`;
      })
      .replace(/\n/g, "<br>"); // Reemplazar saltos de línea por <br>

    return result;
  };
  let lastDate: string | null = null;

  return (
    <ChatBubbleContainer>
      {messages.length === 0 ? (
        <ChatBubble className="left">
          <p>No hay mensajes</p>
        </ChatBubble>
      ) : (
        messages.map((message: any) => {
          const messageDate = format(new Date(message.timestamp), "dd/MM/yyyy");
          const showDate = lastDate !== messageDate;
          lastDate = messageDate;

          return (
            <React.Fragment key={message._id}>
              {showDate && (
                <div
                  style={{
                    textAlign: "center",
                    margin: "10px 0",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontStyle: "italic", textAlign: "center" }}>
                    {messageDate}
                  </span>
                </div>
              )}
              <ChatBubble className={message.direction}>
                {message.type === "text" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: findHTMLInText(message.message),
                    }}
                  />
                )}
                {message.type === "image" && (
                  <div>
                    <img
                      src={message.mediaUrl}
                      alt="Imagen"
                      style={{ maxWidth: "200px", cursor: "pointer" }}
                      onClick={() => window.open(message.mediaUrl, "_blank")}
                    />
                  </div>
                )}
                {message.type === "video" && (
                  <div>
                    <video controls style={{ maxWidth: "100%" }}>
                      <source src={message.mediaUrl} type="video/mp4" />
                      Tu navegador no soporta la etiqueta de video.
                    </video>
                    {message.message && <p>{message.message}</p>}
                  </div>
                )}
                {message.type === "audio" && (
                  <div>
                    <audio controls>
                      <source src={message.mediaUrl} type="audio/mpeg" />
                      Tu navegador no soporta la etiqueta de audio.
                    </audio>
                  </div>
                )}
                {message.type === "document" && (
                  <div>
                    <FaFile />
                    <a
                      href={message.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="application/pdf"
                    >
                      {message.message || "Documento recibido"}
                    </a>
                  </div>
                )}
                {message.type === "location" && (
                  <div>
                    <p>
                      Ubicación: Lat {message.latitude}, Lon {message.longitude}
                    </p>
                  </div>
                )}
                <p>{format(new Date(message.timestamp), "HH:mm")}</p>
              </ChatBubble>
            </React.Fragment>
          );
        })
      )}
    </ChatBubbleContainer>
  );
};

export default ChatMessages;
