import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import {
  ChatContainer,
  ChatItem,
  LastMessage,
  SearchContainer,
} from "./HomePageStyles";
import { fetchChatList } from "../../services/chats/chatServices";
import { Chat } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme";
import Loading from "../../components/Loading/Loading";

const HomePage = () => {
  const navigate = useNavigate();
  const [chatList, setChatList] = useState<Chat[]>([]);

  const getChatList = async () => {
    const response = await fetchChatList();

    setChatList(response);
  };

  useEffect(() => {
    getChatList();
  }, []);

  return (
    <div>
      <h1
        style={{
          textAlign: "center",
        }}
      >
        Chats
      </h1>
      <SearchContainer>
        <FaSearch />
        <input type="text" name="" id="" />
      </SearchContainer>
      <ChatContainer>
        {chatList.length > 0 ? (
          chatList.map((chat) => (
            <ChatItem
              key={chat._id}
              onClick={() => {
                navigate(`/chat/${chat.contact}`);
              }}
              style={{
                borderLeft: chat.unreadCount
                  ? `5px solid ${theme.colors.primary}`
                  : "white",
              }}
            >
              <div style={{ width: "20%" }}>
                <img src={"https://via.placeholder.com/50x50.png"} alt="" />
              </div>
              <div style={{ width: "50%" }}>
                <h3>{chat.name}</h3>
                <LastMessage>{chat.lastMessage}</LastMessage>
              </div>
              <div style={{ width: "25%" }}>
                <p>
                  {chat.lastMessageTime
                    ? new Date(chat.lastMessageTime).toLocaleTimeString()
                    : ""}
                </p>
              </div>
            </ChatItem>
          ))
        ) : (
          <Loading />
        )}
      </ChatContainer>
    </div>
  );
};

export default HomePage;
