// src/store.js
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    // Añade aquí tus reducers
  },
});

export default store;
