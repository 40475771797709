import React from "react";
import { LoadingContainer, LoadingContent } from "./LoadingStyles";

const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingContent>
        <img src="/images/icono.png" alt="" width={50} />
      </LoadingContent>
    </LoadingContainer>
  );
};

export default Loading;
