import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  input {
    border: none;
    background-color: transparent;
    margin-left: 0.5rem;
    width: 100%;
    outline: none;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 70vh;
`;

export const ChatItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #f0f0f0;

  img {
    border-radius: 50%;
    margin-right: 1rem;
  }

  h3 {
    margin: 0;
    font-size: 15px;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
`;

export const LastMessage = styled.p`
  font-size: 12px;
  color: #777;
  margin-top: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
