import styled from "styled-components";

export const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: auto;
  max-height: 70vh;
  padding: 10px;
`;

export const ChatBubble = styled.div<{ className: string }>`
  /* Estilo común para todas las burbujas de chat */
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0;
  max-width: 80%;
  word-wrap: break-word;

  img {
    max-width: 50px;
  }

  /* Estilo para mensajes entrantes */
  &.incoming {
    background-color: #e0f7fa;
    align-self: flex-start;
  }

  /* Estilo para mensajes salientes */
  &.outgoing {
    background-color: #c8e6c9;
    align-self: flex-end;
  }
`;
