// src/services/chatService.ts
import { Chat, Message, SendMessagePayload, Template } from "../../types/types";
import axiosInstance from "../axiosConfig";

export const fetchMessages = async (
  contact: string,
  page: number,
  limit: number
): Promise<{ messages: Message[]; totalPages: any }> => {
  try {
    const response = await axiosInstance.get("/chat/messages", {
      params: { contact, page, limit },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error fetching messages:", error);
    throw new Error(error.response?.data?.message || "Error fetching messages");
  }
};

export const fetchChatList = async (): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get("/chat/chat-list", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data.chatList;
  } catch (error: any) {
    console.error("Error fetching chat list:", error);
    throw new Error(
      error.response?.data?.message || "Error fetching chat list"
    );
  }
};

export const subscribeToNewMessages = (
  socket: any, // Pasa el socket como argumento
  cb: (message: Message) => void
): void => {
  if (!socket) {
    console.error("Socket not initialized");
    return;
  }

  socket.on("newMessage", (message: Message) => {
    cb(message);
  });
};

export const unsubscribeFromNewMessages = (
  socket: any, // Pasa el socket como argumento
  cb: (message: Message) => void
): void => {
  if (!socket) {
    console.error("Socket not initialized");
    return;
  }

  socket.off("newMessage", cb);
};

export const sendMessage = async (
  messageData: SendMessagePayload
): Promise<Message> => {
  try {
    const response = await axiosInstance.post("/chat/send", messageData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error sending message:", error);
    throw new Error(error.response?.data?.message || "Error sending message");
  }
};

export const markAsRead = async (contact: string): Promise<void> => {
  try {
    await axiosInstance.put(
      "/chat/mark-as-read",
      { contact },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error: any) {
    console.error("Error marking as read:", error);
    throw new Error(error.response?.data?.message || "Error marking as read");
  }
};

export const deleteChat = async (id: string) => {
  try {
    await axiosInstance.delete(`/chat/delete/${id}`);
  } catch (error: any) {
    console.error("Error deleting chat:", error);
    throw new Error(error.response?.data?.message || "Error deleting chat");
  }
};

export const searchChat = async (search: string): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get("/chat/search", {
      params: { query: search },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error searching chat:", error);
    throw new Error(error.response?.data?.message || "Error searching chat");
  }
};

export const getTemplates = async () => {
  try {
    const response = await axiosInstance.get("/chat/templates");
    return response.data.data;
  } catch (error: any) {
    console.error("Error fetching templates:", error);
    throw new Error(
      error.response?.data?.message || "Error fetching templates"
    );
  }
};

export const sendMessageTemplate = async (
  messageData: Template
): Promise<any> => {
  try {
    const response = await axiosInstance.post(
      "/chat/send-template",
      messageData
    );

    return response.data;
  } catch (error: any) {
    console.error("Error sending message:", error);
    throw new Error(error.response?.data?.message || "Error sending message");
  }
};
