// src/App.tsx
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import store from "./store";
import Home from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import Navbar from "./components/navbar/Navbar";
import ChatDetail from "./pages/ChatDetail/ChatDetail";
import Protected from "./routes/Protected";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <Protected>
                <Home />
              </Protected>
            }
          />
          <Route
            path="/chat/:id"
            element={
              <Protected>
                <ChatDetail />
              </Protected>
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
