// src/axiosConfig.js
import axios from "axios";

const token = localStorage.getItem("token");

// Configura la instancia de Axios con la URL base desde .env
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

// Puedes agregar interceptores si lo necesitas
apiClient.interceptors.request.use(
  (config) => {
    // Puedes agregar tokens o cualquier otro encabezado aquí
    return config;
  },
  (error) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Manejo de errores global
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return Promise.reject(error);
  }
);

export default apiClient;
