import React, { useState } from "react";
import { Form } from "./LoginPageStyles";
import authService from "../../services/auth/authService";
import { LoginForm } from "../../types/types";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.email || !form.password) {
      console.log("Please fill all the fields");
      return;
    }

    const response = await authService.loginUser(form);

    const token = response.token.split(" ")[1];

    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(response.user));

    if (response.token) {
      navigate("/");
    } else {
      setError("Invalid credentials");
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <Form onSubmit={submitHandler}>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Email"
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
        <input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
        />
        <button type="submit">Login</button>
      </Form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default LoginPage;
